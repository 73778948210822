<template>
  <div>
    <div class="card-body pt-0">

      <div class="row">
        <div class="table-responsive">
          <table id="users-list-datatable" class="table">
            <thead>
            <tr>
              <th class="position-relative" style="width: 5%">Value</th>
              <th class="position-relative" style="width: 20%">Name</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(itemCondition,index) in settingProductItemCondition?.value ?? []" :key="index">
              <td>{{ itemCondition.value }}</td>
              <td>{{ itemCondition.name }}</td>

            </tr>
            </tbody>
          </table>
        </div>

        <div class="col-12">
          <div class="text-danger"><span>*For update product item condition, please contact with system Webmaster.</span></div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";


export default {
  name: "SettingProductItemCondition",
  mixins: [ShowToastMessage, Loader],
  props: ['productItemCondition'],
  data() {
    return {
      currentSetting: this.productItemCondition,
      itemCondition: [],
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      settingProductItemCondition: 'appSettings/settingProductItemCondition',

    }),
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),
  },
}
</script>

<style scoped>

</style>